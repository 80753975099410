.file_add{
  display: flex;
  padding-bottom: 10px;
  align-items: center;
  cursor: pointer;
  span{
    padding-left: 7px;
    color: #0253B3;
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
  }
}

.file_add:last-of-type{
  padding: 0;
}

.item_page{
  >.item_page_block:last-of-type{
    margin-bottom: 60px;
  }
  >.item_page_block:first-of-type{
    margin-bottom: 30px;
  }
}

.refund_requests_page_block{
  .transactions_table{
    .transactions_columns {
      width: 100%;
      .table_header {
        .row_item {
          display: flex;
          align-items: center;
        }
      }
      .table_body{
        .table_row{
          & > :nth-child(3) {
            font-weight: 600;
          }
        }
      }
      .table_row {
        & > :first-child {
          width: 25%;
        }
        & > :nth-child(2) {
          width: 25%;
        }
        & > :nth-child(3) {
          width: 25%;
        }
        & > :last-child {
          width: 25%;
        }
      }
    }
  }
}

.wrapper_btn_items{
  display: flex;
  .default_button_wrapper{
    margin-right: 15px;
  }
}

.descriptions_dialog_component{
  color: #334150;
  font-size: 16px;
  line-height: 20px;
}

.block_status_info > span.text {
  padding-bottom: 0;
}

textarea {
  font-family: "Akrobat Regular", sans-serif;
  resize: none;
  box-sizing: border-box;
  min-height: 100px;
  font-size: 14px;
  width: 100%;
  border: 1px solid #c2d1d9;
  border-radius: 3px;
  padding: 12px 11px;
  &::placeholder {
      font-family: "Akrobat Regular", sans-serif;
      color: #7f8fa4;
      font-size: 14px;
      font-weight: 300;
  }
  &:hover {
    border-color: var(--color-constants-blue-light);
  }
  &:focus {
    border-color: var(--color-constants-blue-light);
    box-shadow: 0 0 0 3px var(--color-constants-blue-lightest);
  }
  // firefox
  &:focus-visible {
    outline: 0;
  }
}