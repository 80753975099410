.mexcar-auto {
    display: inline;
    color: #0253b3;
    cursor: pointer;
}
.download-file {
    display: flex;
    align-items: center;
    margin-bottom: 41px;
    .download {
        color: #7f8fa4;
        font-size: 14px;
        font-weight: 300;
        line-height: 18px;
        box-sizing: border-box;
        border: 1px solid #c2d1d9;
        border-radius: 3px 0 0 3px;
        height: 41px;
        display: flex;
        align-items: center;
        padding-left: 12px;
        min-width: 301px;
        cursor: default;
    }
    .default_button_wrapper {
        margin-left: -98px;
        cursor: pointer;
    }
    .default_button_wrapper .default_button {
        border-radius: 0 3px 3px 0;
        height: 41px;
        min-width: 98px;
    }
    .download-file_error {
        cursor: pointer;
        margin-left: 15px;
        svg {
            fill: #ff0000;
        }
    }
}
.download_file {
    position: relative;
    //overflow: hidden;
    .text-info {
        color: #334150;
        font-size: 16px;
        line-height: 18px;
        padding-bottom: 30px;
    }
    #file {
        display: none;
    }

    .select_wrapper {
        width: 301px;
        margin-right: 49px;
        .select__value-container {
            height: 100%;
        }
        .select__single-value {
            color: #334150;
        }
        .select__menu {
            .select__option--is-selected {
                color: #334150 !important;
            }
            .select__menu-list {
                padding: 8px 8px !important;
            }
            .select__menu-list > div {
                color: #7f8fa4;
                box-sizing: border-box;
                border-bottom: none;
                padding: 10px 10px !important;
                background: #fff !important;
                cursor: pointer;
            }
            .select__option {
                &:hover {
                    background-color: #deebff !important;
                    -webkit-transition: all 0.3s linear;
                    -moz-transition: all 0.3s linear;
                    -ms-transition: all 0.3s linear;
                    -o-transition: all 0.3s linear;
                    transition: all 0.3s linear;
                }
            }
        }
    }
}
