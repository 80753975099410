.burger-button-default {
  width: 20px;
  height: 17px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #FFFFFF;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }


  span:nth-child(1) {
    top: 0;
  }

  span:nth-child(2), span:nth-child(3) {
    top: 5px;
  }

  span:nth-child(4) {
    top: 10px;
  }

  &_open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
  }

  &_open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &_open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  &_open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
  &_sidebar {
    position: absolute;
    top: 35px;
    right: 35px;
  }

}

