:root {
    --color-constants-blue-lightest: #DAECFF;
    --color-constants-blue-light: #b6daff;
    --color-constants-blue-normal: #0072E5;
    --color-constants-blue-dark: #0253B3;
    --color-constants-blue-darkest: #003A75;

    --color-constants-grey-lightest: #6e7781;
    --color-constants-grey-light: #57606a;
    --color-constants-grey-normal: #424a53;
    --color-constants-grey-dark: #32383f;
    --color-constants-grey-darkest: #24292f;
}