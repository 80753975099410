
.control_panel--page-size{
  display: flex;
  margin-left: auto;
  .radio-wrapper input[type="radio"] {
    display: none;
  }
  .radio-wrapper input[type="radio"]:checked + label {
    border-radius: 2px;
    background-color: #0253B3;
    box-shadow: 0 2px 42px 0 rgba(0, 0, 0, 0.08);
    color: #ffffff;
    &:hover {
      opacity: 0.7;
    }
  }
  .radio-wrapper label {
    border-radius: 2px;
    width: 28px;
    height: 28px;
    min-width: 28px;
    font-size: 14px;
    background: transparent;
    color: #0253B3;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 3px;
    cursor: pointer;
    transition: all ease .3s;
    &:hover {
      border: 1px solid #0253B3;
    }
  } 
}