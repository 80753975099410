.panel_page{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  .search_transactions_panel{
    width: 471px;
    .search_product_wrapper{
      width: 100%;
    }
    .search_transactions_input_icon{
      display: flex;
      align-items: center;
      padding-right: 12px;
    }
  }
}

.select_wrapper{
   width: 201px;
   height: 41px;
   .select__menu{
     .select__menu-list{
       padding: 0 8px!important;
       >div{
         color: #7F8FA4;
         box-sizing: border-box;
         border-bottom: 1px solid #C2D1D9;
         padding: 15px 0px!important;
         background: #fff!important;
         cursor: pointer;
         &:hover{
           background: #fff!important;
         }
       }
       >div:last-of-type{
         border-bottom: none;
       }
     }
   }
 }
.select__value-container:hover {
  cursor: pointer;
}

.select__indicators:hover {
  cursor: pointer;
}

.search_transactions_list {
  width: 427px;
  .search_transactions_item {
    box-sizing: border-box;
    border-bottom: 1px solid #C2D1D9;
    padding: 15px 0px!important;
    background: #fff!important;
    cursor: pointer;
  }
  & > :last-child {
    border-bottom: none;
  }
  .search_transactions_no_items {
    box-sizing: border-box;
    padding: 15px 0px!important;
    background: #fff!important;
  }
}