.no-items{
  border-radius: 5px;
  background-color: #FFFFFF;
  display: flex;
  width: 100%;
  flex-flow: column;
  padding: 40px 0;
  align-items: center;
  margin: 30px 0 50px;
  .item-block{
    text-align: center;
    .box-img{
      padding-bottom: 20px;
    }
    .title{
      color: #334150;
      font-size: 16px;
      line-height: 20px;
      padding-bottom: 10px;
    }
    .descriptions{
      color: #7F8FA4;
      font-size: 14px;
      font-weight: 300;
      line-height: 17px;
    }
  }
}