@font-face {
  font-family: "Akrobat Light";
  src: url('../assets/fonts/Akrobat-Light.eot') format("eot");
  src: url('../assets/fonts/Akrobat-Light.woff2') format("woff2"),
       url('../assets/fonts/Akrobat-Light.woff') format("woff"),
       url('../assets/fonts/Akrobat-Light.ttf') format("truetype");
}

@font-face {
  font-family: "Akrobat Regular";
  src: url('../assets/fonts/Akrobat-Regular.eot') format("eot");
  src: url('../assets/fonts/Akrobat-Regular.woff2') format("woff2"),
  url('../assets/fonts/Akrobat-Regular.woff') format("woff"),
  url('../assets/fonts/Akrobat-Regular.ttf') format("truetype");
}

@font-face {
  font-family: "Akrobat SemiBold";
  src: url('../assets/fonts/Akrobat-SemiBold.eot') format("eot");
  src: url('../assets/fonts/Akrobat-SemiBold.woff2') format("woff2"),
  url('../assets/fonts/Akrobat-SemiBold.woff') format("woff"),
  url('../assets/fonts/Akrobat-SemiBold.ttf') format("truetype");
}

@font-face {
  font-family: "Akrobat Bold";
  src: url('../assets/fonts/Akrobat-Bold.eot') format("eot");
  src: url('../assets/fonts/Akrobat-Bold.woff2') format("woff2"),
  url('../assets/fonts/Akrobat-Bold.woff') format("woff"),
  url('../assets/fonts/Akrobat-Bold.ttf') format("truetype");
}

@font-face {
  font-family: "Akrobat ExtraBold";
  src: url('../assets/fonts/Akrobat-ExtraBold.eot') format("eot");
  src: url('../assets/fonts/Akrobat-ExtraBold.woff2') format("woff2"),
  url('../assets/fonts/Akrobat-ExtraBold.woff') format("woff"),
  url('../assets/fonts/Akrobat-ExtraBold.ttf') format("truetype");
}

@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap&subset=cyrillic-ext');


@import "./reset";
@import "./base";
@import "./animations";
@import "./colors.scss";