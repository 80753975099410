.orders-main-block {
  .flex-center-btw {
    .flex-center-start {
      .title-block {
        margin-top: 50px ;
      }
    }
    .add_button{
      margin-top: 50px ;
      font-size: 16px;
    }
  }
  .dashboard-section_content {
    margin: 0 0 135px;
    padding: 40px 50px 10px 50px;
    background: #fff;
    h1 {
      height: 40px;
      width: 105px;
      font-weight: 300;
      font-size: 32px;
      line-height: 40px;
    }
    .transactions_columns {
      width: 100%;
      .table_header {
        .row_item {
          display: flex;
          align-items: center;
        }
      }
      .table_row {
        & > :first-child {
          width: 30%;
        }
        & > :nth-child(2) {
          width: 30%;
        }
        & > :nth-child(3) {
          width: 30%;
        }
        & > :last-child {
          width: 10%;
          justify-content: flex-end;
          display: flex;
        }
        .delete_btn{
          cursor: pointer;
          background: transparent;
          font-family: "Akrobat Regular", sans-serif;
          font-size: 16px;
          color: #D0021B;
          &:hover{
            opacity: 0.7;
          }
        }
      }
    }
    .descriptions {
      margin: 0 15% 0 15%;
    }
  }
}
.dialog-delete-wrapper{
  .title{
    color: #334150;
    font-size: 32px;
    line-height: 40px;
    padding-bottom: 30px;
  }
  .descriptions{
    color: #334150;
    font-size: 16px;
  }

  .search_wrapper {
    position: relative;
    background-color: #FFFFFF;
    border-radius: 3px;
    border: 1px solid #C2D1D9;
    height: 40px;
    width: 100%;
    display: flex;
    &.search_wrapper_small {
      width: 471px;
      justify-content: space-between;
      &>div:first-child {
        width: calc(100% - 34px);
      }
      .search_input_icon {
        display: flex;
        align-items: center;
        width: 34px;
      }
    }
    input {
      width: calc(100% - 34px);
      height: 38px;
      border-radius: 3px;
      line-height: 38px;
      padding: 0 12px;
      font-size: 14px;
      font-family: "Akrobat Regular", sans-serif;
      &::placeholder {
        color: #C2D1D9;
      }
    }
    button {
      padding: 5px 7px;
      border-radius: 0;
      svg {
        font-size: 20px;
        fill: #C2D1D9;
      }
    }
  }
}

.dialog-button-wrapper{
  border-top: 1px solid #C2D1D9;
  padding-top: 30px;
  margin-top: 28px;
  display: flex;
  button{
    margin-right: 15px;
  }
}

.blacklist-dialog {
  z-index: 0!important;
}

.blacklist_ul {
  width: 471px;
  li {
    box-sizing: border-box;
    border-bottom: 1px solid #C2D1D9;
    padding: 15px 20px!important;
    background: #fff!important;
    cursor: pointer;
    width: 100%;
    text-align: left;
    &:hover {
      background-color: #DEEBFF !important;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear;
      -ms-transition: all 0.3s linear;
      -o-transition: all 0.3s linear;
      transition: all 0.3s linear;
    }
  }
  & > :last-child {
    border-bottom: none;
  }
}

.MuiAutocomplete-noOptions {
  font-family: "Akrobat Regular", sans-serif!important;
}

#products_popper-popup {
  .MuiAutocomplete-option[data-focus="true"] {
    background-color: #DEEBFF !important;
  }
  li {
    font-family: "Akrobat Regular", sans-serif;
    &:hover {
      background-color: #DEEBFF !important;
    }
  }
}