.import_orders_inner{
  & > .title-block {
    h1 {
      margin-top: 50px;
      margin-bottom: 30px;
    }
  }
  .tab_custom{
    padding-bottom: 10px;
    button{
      margin-right: 34px;
    }
  }
  .wrapper_btn_table{
    display: flex;
    padding-top: 30px;
  }
  .block_table{
    .title_table{
      font-family: "Akrobat SemiBold", sans-serif;
      font-size: 23px;
      font-weight: 600;
      line-height: 29px;
      padding-top: 40px;
      padding-bottom: 5px;
    }
  }
  .orders_inner_table{
    .transactions_columns {
      width: 100%;
      .table_row {
        height: auto;
        padding: 10px 0;
        align-items: center;
        border-top: none;
        border-bottom: 2px solid rgba(0, 0, 0, 0.1);
        span{
          color: #7F8FA4;
          font-family: "Akrobat Light", sans-serif;
          font-size: 12px;
          padding-bottom: 4px;
        }
        & > :first-child {
          width: 15%;
        }
        & > :nth-child(2) {
          width: 15%;
        }
        & > :nth-child(3) {
          width: 18%;
        }
        & > :nth-child(4) {
          width: 12%;
        }
        & > :nth-child(5) {
          width: 14%;
        }
        & > :nth-child(6) {
          width: 12%;
        }
        & > :last-child {
          width: 15%;
          justify-content: flex-end;
          display: flex;
          button{
            transition: all 0.3s linear;
            color: #0253B3;
            font-size: 16px;
            line-height: 20px;
            background: transparent;
            cursor: pointer;
            &:hover{
              opacity: 0.7;
            }
          }
        }
        & > .row_item__buttons {
          flex-direction: column;
          .button_moving {
            color: #D0021B;
            font-size: 13px;
            margin-top: 10px;
          }
        }
      }
    }
    .expensive-cost {
      color: #D0021B;
    }
  }
  .undistributed_goods{
    padding-top: 25px;
    .transactions_columns {
      width: 100%;
      .table_row {
        height: 70px;
        border-top: none;
        border-bottom: 2px solid rgba(0, 0, 0, 0.1);
        span{
          color: #7F8FA4;
          font-family: "Akrobat Light", sans-serif;
          font-size: 12px;
          padding-bottom: 4px;
        }
        & > :first-child {
          width: 25%;
        }
        & > :nth-child(2) {
          width: 25%;
        }
        & > :nth-child(3) {
          width: 27%;
        }
        & > :nth-child(4) {
          width: 23%;
        }
        & > :nth-child(5) {
          width: 14%;
        }
        & > :nth-child(6) {
          width: 12%;
        }
        & > :last-child {
          width: 15%;
          justify-content: flex-end;
          display: block;
          button{
            transition: all 0.3s linear;
            color: #0253B3;
            font-size: 16px;
            line-height: 20px;
            background: transparent;
            cursor: pointer;
            &:hover{
              opacity: 0.7;
            }
          }
        }
      }
    }
  }
}
.change_supplier_title{
  color: #334150;
  font-size: 32px;
  line-height: 40px;
  font-weight: 400;
}
.block_table_wrapper{
  .table_body{
    padding: 0 10px;
  }
  .table_row{
    border-top: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    & > :first-child {
      width: 50%;
    }
    & > :nth-child(2) {
      width: 35%;
    }
    & > :nth-child(3) {
      width: 25%;
    }
    .row_item{
      display: flex;
      cursor: pointer;
      flex-grow: 1;
      align-items: center;
      p{
        color: #01397C;
      }
      span{
        margin-right: 10px;
        font-size: 12px;
        color: #7F8FA4;
      }
    }
  }
  p{
    height: 20px;
    color: #7F8FA4;
    font-size: 16px;
    line-height: 20px;
  }
}

.scroll_block{
  max-height: 400px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    -webkit-border-radius: 2px;
    border-radius: 2px;
    margin-right: 5px;
    background: #EDF2F8;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #01397C;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: #01397C;
  }
}

.change_supplier_serach{
  width: 450px;
  margin: 30px 0;
  .search_product_wrapper{
    input{
      width: calc(450px - 34px);
    }
  }
  .search_input_icon{
    line-height: 38px;
  }
}

.orders_inner_load{
  height: calc(100vh - 80px);
}