.product_selection {
    .descriptions {
        color: #334150;
        font-size: 16px;
        line-height: 18px;
        padding-bottom: 20px;
    }
    .search_block {
        position: relative;
        margin-bottom: 30px;
        display: inline-block;
        box-sizing: border-box;
        .select__value-container {
            height: 100% !important;
        }
        input {
            box-sizing: border-box;
            height: 45px;

            width: 471px;
            border: 1px solid #c2d1d9;
            border-radius: 3px;
            padding: 0 40px 0 12px;
            color: #7f8fa4;
            font-size: 14px;
            font-weight: 300;
            line-height: 18px;
        }

        button {
            cursor: pointer;
            background: transparent;
            position: absolute;
            right: 12px;
            top: calc(50% - 9px);
        }
    }
    .custom-input {
        display: flex;
        align-items: flex-end;
        input {
            width: 266px;
        }
    }

    .btn_wrapper_product {
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        padding-top: 30px;
        display: flex;
        .default_button_wrapper {
            margin-right: 15px;
        }
        &:last-child {
            margin-left: auto;
            margin-right: 0;
        }
    }
    .product_wrapper_table {
        display: flex;
        justify-content: space-between;
        padding-bottom: 30px;
        .line_v {
            width: 1px;
            margin: 0 30px;
            background: rgba(0, 0, 0, 0.1);
        }
        .title_table {
            color: #334150;
            font-size: 23px;
            line-height: 29px;
            padding-bottom: 10px;
        }
        .no_items {
            color: #7f8fa4;
            font-size: 16px;
            line-height: 20px;
            padding-top: 20px;
        }
    }
    .table_left {
        width: 48%;
        .transactions_columns {
            width: 100%;
            .table_header {
                .row_item {
                    display: flex;
                    align-items: center;
                }
            }
            .table_row {
                & > :first-child {
                    width: 20%;
                }
                & > :nth-child(2) {
                    width: 35%;
                }
                & > :nth-child(3) {
                    width: 35%;
                }

                & > :last-child {
                    width: 10%;
                }
            }
        }
    }
    .table_right {
        width: 48%;
        .transactions_columns {
            width: 100%;
            .table_header {
                .row_item {
                    display: flex;
                    align-items: center;
                }
            }
            .table_row {
                & > :first-child {
                    width: 20%;
                }
                & > :nth-child(2) {
                    width: 30%;
                }
                & > :nth-child(3) {
                    width: 22%;
                }
                & > :nth-child(4) {
                    width: 15%;
                }

                & > :last-child {
                    width: 13%;
                    display: flex;
                    justify-content: flex-end;
                    button {
                        cursor: pointer;
                        background: transparent;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
    }
}
.table_body_scroll {
    overflow: auto;
    max-height: 550px;
    &::-webkit-scrollbar {
        width: 4px;
    }
    &::-webkit-scrollbar-track {
        -webkit-border-radius: 2px;
        border-radius: 2px;
        margin-right: 5px;
        background: #edf2f8;
    }
    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: #01397c;
    }
    &::-webkit-scrollbar-thumb:window-inactive {
        background: #01397c;
    }
}
.table_dialog_items {
    margin-top: 15px;
    .transactions_columns {
        width: 100%;
        .table_row {
            border-bottom: 2px solid rgba(0, 0, 0, 0.1);
            border-top: none;
            cursor: pointer;
            -webkit-transition: all ease 0.5s;
            -moz-transition: all ease 0.5s;
            -ms-transition: all ease 0.5s;
            -o-transition: all ease 0.5s;
            transition: all ease 0.5s;
            &:hover {
                background-color: #edf2f8;
            }
            .name_items {
                color: #01397c;
                font-size: 16px;
                font-weight: 600;
                line-height: 20px;
            }
            .block_table {
                display: flex;
                flex-flow: column;
                .title_table {
                    color: #7f8fa4;
                    font-size: 12px;
                    font-weight: 300;
                    line-height: 15px;
                }
                .info_item {
                    min-height: 17px;
                    color: #334150;
                    font-size: 14px;
                    line-height: 17px;
                }
            }
        }
        .table_row:last-of-type {
            border: none;
        }
        .table_header {
            .row_item {
                display: flex;
                align-items: center;
            }
        }
        .table_row {
            & > :first-child {
                width: 20%;
            }
            & > :nth-child(2) {
                width: 20%;
            }
            & > :nth-child(3) {
                width: 15%;
            }
            & > :nth-child(4) {
                width: 17%;
            }
            & > :nth-child(5) {
                width: 15%;
            }
            & > :last-child {
                display: flex;
                flex-flow: row;
            }
        }
    }
}

.product_wrapper {
    border: 1px solid #c2d1d9;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 30px;
    .table_dialog_items {
        margin: 0;
        .table_row {
            height: auto;
            &:hover {
                background: none;
            }
            .name_items {
                color: #334150;
                font-size: 23px;
            }
        }
    }
}
.amount_wrapper {
    padding-top: 20px;
    span {
        color: #7f8fa4;
        font-size: 12px;
        font-weight: 300;
        line-height: 18px;
    }
}
.amount {
    border: 1px solid #c2d1d9;
    border-radius: 3px;
    height: 41px;
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 20px;
    input {
        color: #334150;
        width: 30px;
        height: 100%;
        text-align: center;
    }
    button {
        cursor: pointer;
        background: transparent;
        height: 100%;
        display: flex;
        align-items: center;
        width: 30px;
        position: relative;
        font-size: 30px;
        padding-left: 9px;
    }
}

.no_display {
    display: none;
}

@media print {
    .to-print-sticker {
        font-family: 'Arial';
        width: 58mm;
        height: 40mm;
        font-size: 12px;
        // max-height: 400px;
        background-color: #FFFFFF;

        &__logo {
            width: 100%;
            height: 15mm;
        }
        &__main-info {
            position: relative;
            display: flex;
            justify-content: flex-start;
            height: 100%;
            padding: 0.5mm 3mm 1mm;
        }
        &__left-block {
            display: flex;
            flex-direction: column;
            max-width: 33mm;
            width: auto;
            justify-content: flex-start;
            &-up {
                height: 8mm;
            }
            &-up--article {
                font-weight: 600;
            }
            &-up--comment {
                text-overflow: clip;
                white-space: nowrap;
                overflow: hidden;
            }
        }       
        &__left-block-bottom {
            // max-width: 40mm;
            width: 100%;
            margin-left: auto;
            margin-top: 3mm;
            height: 12mm;
            svg {
                width: 100%;
            }
        }
        &__left-block-bottom--wo-comment {
            margin-top: 2mm;
        }         
        &__center-block {
            text-align: left;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            // max-width: 10mm;
            width: auto;
            margin-left: 2mm;
            &-up {
                height: 8mm;
            }
            &-up--amount {
                font-size: 24px;
                font-weight: 600;
                span {
                    font-size: 12px;
                }
            }
        }
        &__center-block-bottom {
            font-size: 13px;
            // width: 10mm;
            letter-spacing: -1px;
            // margin-left: auto;
            margin-top: 2.5mm;
            height: 12mm;
            p {
                line-height: 5mm;
                &:nth-child(1) {
                    line-height: 4mm;

                }
                &:nth-child(2) {
                    line-height: 4mm;
                }
            }
        }
        &__center-block-bottom--wo-comment {
            margin-top: 1.5mm;
        }        
        &__right-block {
            height: 25mm;
            letter-spacing: -0.7px;
            text-align: center;
            margin-left: 3mm;
            font-size: 12px;
            font-weight: 600;
            writing-mode: vertical-lr;
        }
    }
}
