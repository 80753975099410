.message_popper {
  .message_tooltip {
    background-color: #29323C;
    font-size: 10px;
    word-break: break-word;
    font-family: "Akrobat Regular", sans-serif;
  }
  .error_message_tooltip {
    background-color: #ff0000;
    font-size: 10px;
    font-family: "Akrobat Regular", sans-serif;
  }
}

body {
  .auth_tooltip_popper {
    top: 8px !important;
    left: 9px;
  }
}