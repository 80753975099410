.orders_page {
  .select_wrapper {
    width: 171px;
  }
  .panel_page .search_transactions_panel {
    width: 390px;
  }
  .control_panel {
    display: flex;
    padding: 30px 0 0 0;
    &--page-size {
      display: flex;
      margin-left: auto;
    }
  }
  .pagination-container{
    padding: 0;
  }
  .radio-wrapper input[type="radio"] {
    display: none;
  }
  .radio-wrapper input[type="radio"]:checked + label {
    border-radius: 2px;
    background-color: #0253B3;
    box-shadow: 0 2px 42px 0 rgba(0, 0, 0, 0.08);
    color: #ffffff;
    &:hover {
      opacity: 0.7;
    }
  }
  .radio-wrapper label {
    border-radius: 2px;
    width: 28px;
    height: 28px;
    min-width: 28px;
    font-size: 14px;
    background: transparent;
    color: #0253B3;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 3px;
    cursor: pointer;
    transition: all ease .3s;
    &:hover {
      border: 1px solid #0253B3;
    }
  }  
}

.orders_table_wrapper{
  .transactions_table{
    .table_row{
      border-bottom: 2px solid rgba(0, 0, 0, 0.1);
      border-top: none;
      padding-left: 5px;
    }
    .transactions_columns {
      width: 100%;
      .table_body{
        .table_row{
          & > :nth-child(2) {
            font-weight: 600;
          }
          & > :nth-child(8) {
            font-weight: 600;
          }
        }
      }
      .table_header {
        .row_item {
          width: 100%;
          display: flex;
          align-items: center;
          color: #7F8FA4 !important;
          margin-right: 5px;
        }
      }
      .table_body {
        .row_item {
          width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          margin-right: 5px;
        }
      }      
      .table_row {
        & > :first-child {
          width: 8%;
        }
        & > :nth-child(2) {
          width: 5%;
        }
        & > :nth-child(3) {
          width: 8%;
        }
        & > :nth-child(4) {
          width: 6%;
        }
        & > :nth-child(5) {
          width: 8%;
        }
        & > :nth-child(6) {
          width: 8%;
        }
        & > :nth-child(7) {
          width: 4%;
        }
        & > :nth-child(8) {
          width: 4%;
        }
        & > :nth-child(9) {
          width: 5%;
        }
        & > :nth-child(10) {
          width: 4%;
          color: #D0021B;
        }
        & > :nth-child(11) {
          width: 5%;
          text-align: center;
        }
        & > :nth-child(12) {
          width: 8%;
        }
        & > :nth-child(13) {
          width: 5%;
          margin-right: 5px;
        }
        & > :nth-child(14) {
          width: 5%;
        }
        & > :last-child {
          width: 11%;
          .select_wrapper {
            width: 130px;
          }
        }
      }
    }
  }
  .amount {
    width: 100%;
    input {
      width: 100%;
    }
  }
}

.block_status_info{
  display: flex;
  align-items: center;
  .text{
    color: #334150;
    font-size: 16px;
    padding-left: 5px;
  }
  .status{
    border-radius: 50%;
    height: 6px;
    width: 6px;
  }
}

.ru_value{
  font-family: Roboto, sans-serif;


  font-weight: 500;
}

.link-orders:hover {
  -webkit-transition: all ease .5s;
  -moz-transition: all ease .5s;
  -ms-transition: all ease .5s;
  -o-transition: all ease .5s;
  transition: all ease .5s;
  background-color: #EDF2F8;
}