.new_issue {
  .page_block{
    .title{
      color: #334150;
      font-size: 32px;
      line-height: 40px;
    }
  }
  .line_v{
    width: 100%;
    height: 1px;
    margin: 30px 0px;
    background:  rgba(0, 0, 0, 0.3);
  }
  .search_block{
    position: relative;
    display: inline-block;
    margin-top: 30px;
    input{
      box-sizing: border-box;
      height: 41px;
      width: 471px;
      border: 1px solid #C2D1D9;
      border-radius: 3px;
      padding: 0 40px 0 12px;
      color: #7F8FA4;
      font-size: 14px;
      font-weight: 300;
      line-height: 18px;
      &::placeholder{
        font-family: "Akrobat Regular", sans-serif;
        color: #7F8FA4;
        font-size: 14px;
        font-weight: 300;
        line-height: 18px;
      }
    }
    button{
      cursor: pointer;
      background: transparent;
      position: absolute;
      right: 12px;
      top: calc(50% - 9px);
    }

  }
  .transactions_columns {
    width: 100%;
    .table_header {
      .row_item {
        display: flex;
        align-items: center;
      }
    }
    .table_row {
      & > :first-child {
        width: 24%;
        padding-right: 6px;
      }
      & > :nth-child(2) {
        width: 15%;
      }
      & > :nth-child(3) {
        width: 15%;
      }
      & > :nth-child(4) {
        width: 12%;
      }
      & > :nth-child(5) {
        width: 17%;
      }      
      & > :last-child {
        width: 17%;
      }
    }
  }
  .table_container {
    &__summary {
      float: right;
      font-size: 16px;
      width: 17%;
      color: #334150;
      text-align: start;
    }
  } 
  .info_new_issue{
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 12px;
    box-sizing: border-box;
    height: 59px;
    width: 651px;
    border: 1px solid #C2D1D9;
    border-radius: 3px;
    color: #334150;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    resize: none;
    font-family: "Akrobat Regular", sans-serif;
    &::placeholder{
      font-family: "Akrobat Regular", sans-serif;
      color: #7F8FA4;
    }
  }
  .btn_wrapper{
    justify-content: space-between;
    display: flex;
  }
  .to-print-sticker {
    width: 58mm;
    height: 60mm;
    max-height: 400px;
    background-color: #FFFFFF;
    & > :first-child {
      margin-top: 40px;
      font-size: 23px;
      font-weight: 600;
      align-items: center;
      div {
        img {
          margin-right: 25px;
        }
      }
    }
    & > :nth-child(2) {
      margin-top: 31px;
      & > :first-child {
        display: flex;
        flex-flow: column;
        color: #334150;
        & > :first-child {
          font-size: 23px;
        }
        & > :nth-child(2) {
          font-size: 32px;
          font-weight: bold;
        }
        & > :last-child {
          font-size: 23px;
        }
      }
      & > :last-child {
        display: flex;
        align-items: center;
        flex-flow: row;
        font-size: 23px;
        font-weight: bold;
        h1 {
          font-size: 60px;
        }
      }
    }
    & > :nth-child(3) {
      margin: 16px 0;
    }
    & > :last-child {
      margin-bottom: 40px;
      & > :first-child {
        justify-content: center;
        font-size: 23px;
        display: flex;
        flex-flow: column;
        color: #334150;
      }
    }
    .to-print-line {
      display: flex;
      justify-content: space-between;
    }
    .comment {
      font-size: 16px;
      line-height: 20px;
      width: 90%;
    }
  }  
}

.more-dialog-btn{
  justify-content: space-between;
  display: flex;
  >div{
    display: flex;
  }
}

.print_goods {
  justify-content: space-between;
  .title {
    display: none;
  }
  .transactions_columns {
    width: 100%;
    .table_header {
      .table_row {
        & > :first-child {
          width: 24%;
        }
        & > :nth-child(2) {
          width: 15%;
        }
        & > :nth-child(3) {
          width: 15%;
        }
        & > :nth-child(4) {
          width: 12%;
        }
        & > :nth-child(5) {
          width: 17%;
        }      
        & > :last-child {
          // font-weight: 500;
          // font-family: "Roboto", sans-serif;
          width: 17%;
        }
      }    
      .row_item {
        display: flex;
        align-items: center;
      }
    }
    .table_body {
      .table_row {
        height: auto;
        & > :first-child {
          width: 24%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;          
        }
        & > :nth-child(2) {
          width: 15%;
        }
        & > :nth-child(3) {
          width: 15%;
        }
        & > :nth-child(4) {
          width: 12%;
        }
        & > :nth-child(5) {
          width: 17%;
        }      
        & > :last-child {
          width: 17%;
        }              
        .row_item {
          font-size: 12px;
        }
      }
    }
    .table_row {
      & > :first-child {
        width: 24%;
        margin-right: 3px;        
      }
      & > :nth-child(2) {
        width: 15%;
      }
      & > :nth-child(3) {
        width: 15%;
      }
      & > :nth-child(4) {
        width: 12%;
      }
      & > :nth-child(5) {
        width: 17%;
      }      
      & > :last-child {
        width: 17%;
      }         
    }
  }
  .table_container {
    &__summary {
      margin-top: 16px;
      float: right;
      font-size: 12px;
      width: 17%;
      color: #334150;
      text-align: start;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    &--logo {
      width: auto;
      height: 40px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &--company {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 500;
    }
  }
  &__date {
    display: flex;
    justify-content: flex-end;
  }
}