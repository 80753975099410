.page_not_found {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2%;
  //height: calc(100vh - 50px);
  h1 {
    width: 80%;
    background-color: #FFFFFF;
    color: #334150;
    font-size: 60px;
    line-height: 100px;
    margin-bottom: 30px;
    padding-left: 30px;
    font-weight: 400;
  }
}
