.loader_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  //problematical, careful
  margin-top: 15%;

  & > div {
    color: #0253B3;
  }
}