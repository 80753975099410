.item_page{
  .title_items{
    color: #334150;
    font-size: 32px;
    line-height: 40px;
    padding-bottom: 20px;
  }
  .descriptions_items{
    padding-bottom: 7px;
    color: #7F8FA4;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
  }
  .text{
    padding-bottom: 23px;
    color: #334150;
    font-size: 16px;
    line-height: 18px;
  }
  .title_items_mini{
    display: flex;
    color: #334150;
    font-size: 23px;
    line-height: 29px;
    padding-bottom: 20px;
  }
  .select_wrapper{
    width: 301px;
  }
  .status_wrapper{
    .title_items_mini{
      span{
        margin-right: 5px;
      }
    }
  }
  .btn_wrapper_product{
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    padding-top: 30px;
    display: flex;
    .default_button_wrapper{
      margin-right: 15px;
    }
    .file_error {
      cursor: pointer;
      color: red;
    }
  }
}
.line_wrapper{
  background: #C2D1D9;
  width: 100%;
  height: 1px;
  margin: 30px 0;
}

.half_block_wrapper_items{
  display: flex;
  align-items: center;
  >div:first-of-type{
    width: 30%;
  }
  >div:last-of-type{
    width: 70%;
  }
}
.indent_no{
  margin: 0!important;
  padding: 0!important;
}


.info-social-wrapper{
  padding-left: 20px;
  img{
    padding-right:8px;
  }
}

.descriptions_dialog_component{
  margin-top: 30px;
  color: #334150;
  font-size: 16px;
  line-height: 20px;
}