.waitnig_reception_block {
    margin-bottom: 60px;
    .waitnig_reception {
      background: #FFFFFF;
      padding: 40px 50px 30px;
      .title {
        color: #334150;
        font-size: 32px;
        line-height: 40px;
        padding-bottom: 15px;
      }
      .transactions_columns {
        width: 100%;
        .table_header {
          .row_item {
            display: flex;
            align-items: center;
          }
        }
        .table_body {
          .table_row {
            cursor: pointer;
              &:hover {
                background-color: #DEEBFF;
                -webkit-transition: all 0.3s linear;
                -moz-transition: all 0.3s linear;
                -ms-transition: all 0.3s linear;
                -o-transition: all 0.3s linear;
                transition: all 0.3s linear;
              }
          }
        }
        .table_row {
          & > :first-child {
            width: 45%;
          }
          & > :nth-child(2) {
            width: 35%;
          }
          & > :last-child {
            width: 20%;
            font-weight: 600;
          }
        }
        & > :last-child {
          border-bottom: 2px solid rgba(0, 0, 0, 0.1);
          margin-bottom: 35px;
        }
      }
    }
    .pagination-container{
      padding-top: 0;
      margin-bottom: 5px;
    }
  }
  