.app-head {
  background-color: #01397C;
  a {
    text-decoration: none;
  }
}

.header-wrapper{
  height: 80px;
}

.app-nav {
  margin-left: 80px;
  a{
    font-size: 16px;
  }
}

.nav_link {
  opacity: 0.5;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  padding: 10px 20px;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  &:hover {
    opacity: 1;
  }
  &.active_nav_link {
    opacity: 1;
  }
}

.app-head_options {
  & > :first-child {
    margin-right: 10px;
  }
  .exit_btn {
    opacity: 0.5;
    color: #FFFFFF;
    font-family: "Akrobat Light", sans-serif;
    font-size: 14px;
    line-height: 13px;
    font-weight: 300;
    text-transform: none;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    &:hover {
      opacity: 1;
    }
  }
  .notification_btn {
    padding: 6px;
    svg {
      fill: #ffffff;
      font-size: 28px;
    }
  }
}

.notification_list {
  hr {
    margin: 0;
  }
}

.notification_item {
  padding: 10px 0;
  cursor: pointer;
  .flex-center {
    margin-bottom: 5px;
    & > span, & > img {
      margin-right: 10px;
    }
  }
}

.notification_star {
  position: relative;
  cursor: default;
  display: inline-block;
  color: rgb(245, 166, 35);
  font-size: 18px;
}

.notification_title {
  color: #A7ADB3;
  font-family: "Akrobat Light", sans-serif;
  font-size: 14px;
  line-height: 17px;
  span {
    color: #334150;
    font-family: "Akrobat SemiBold", sans-serif;
    font-weight: 600;
  }
}

.notification_date {
  color: #7F8FA4;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
}