* {
  box-sizing: border-box;
}

body {
  background-color: #EDF2F8;
  color: #424B5A;
  font-family: "Akrobat Regular", sans-serif;

}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.container {
  width: 100%;
  padding: 0 150px;
}
@media screen and (max-width: 1410px) {
  .container {
    padding: 0 100px;
  }
}

button{
  font-family: "Akrobat Regular", sans-serif;
}
.flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-center {
  display: flex;
  align-items: center;
}

.flex-center-btw {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .add_btn {
    margin-right: 0;
  }
}
.flex-center-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

hr {
  height: 1px;
  background-color: #C2D1D9;
  margin: 30px 0;
}

.dashboard_block {
  background-color: #ffffff;
  border-radius: 5px;
  min-height: 197px;
  width: 24.2%;
  display: inline-block;
  padding: 15px 15px 10px;
  margin-bottom: 30px;
  hr {
    margin: 0;
  }
}

.date_wrapper {
  color: #7F8FA4;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  display: flex;
  align-items: center;
  img {
    margin-right: 5px;
  }
}

.block_label {
  color: #7F8FA4;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
}

.block_value {
  color: #334150;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.block_info_item {
  margin-bottom: 10px;
}

.app-back_link {
  margin: 48px 0;
  display: inline-block;
}

.page_options_wrapper {
  width: 50%;
}

.title-block{
  h1 {
    color: #334150;
    font-size: 60px;
    line-height: 75px;
    margin-bottom: 30px;
    font-weight: 400;
  }
}


div {
  .add_btn {
    color: #0253B3;
    font-family: "Akrobat Regular", sans-serif;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    text-transform: none;
    margin-right: -5px;
    padding: 0;
    img {
      margin-left: 9px;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear;
      -ms-transition: all 0.3s linear;
      -o-transition: all 0.3s linear;
      transition: all 0.3s linear;
    }
    &:hover{
      background: none;
      text-decoration: underline;
      img {
        transform: rotate(180deg);
      }
    }
  }
}

div {
  .blue_btn {
    color: #0253B3;
    font-family: "Akrobat Regular", sans-serif;
    font-size: 16px;
    line-height: 20px;
    text-transform: none;
    padding: 0;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    &:hover {
      text-decoration: underline;
      background: transparent;
    }
  }
}

div {
  .red_btn {
    color: #D0021B;

    font-size: 16px;
    line-height: 20px;
    text-transform: none;
    padding: 0;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    &:hover {
      text-decoration: underline;
      background: transparent;
    }
  }
}

.item_page_block{
  padding: 40px 50px;
  margin-bottom: 60px;
  background: #fff;
}

.indent_none{
  margin: 0!important;
  padding: 0!important;
}



.app-back_link{
  cursor: pointer;
}

h3 {
  color: #334150;
  font-family: "Akrobat Regular", sans-serif;
  font-size: 32px;
  font-weight: 100;
}

.table_container{
  .table_header .table_header{
    display: flex;
  }
}

.table_header{
  .row_item{
    color: #7F8FA4;
    font-size: 12px;
  }
}

.table_body{
  .row_item{
    color: #334150;
    font-size: 16px;
    &--plus, &--minus {
      cursor: pointer;
    }
    &--plus {
      margin-left: 10px;
    }
  }
  .table_row{
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    text-decoration: none;

  }

}

.table_row{
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
}


.title-block-page{
  padding: 50px 0 30px;
  align-items: flex-end;
  .title-page{
    h1{
      font-weight: 400;
      color: #334150;
      font-size: 60px;
      line-height: 75px;
    }
  }
}

.page_block{
  background: #fff;
  padding: 50px 50px 30px;
  margin-bottom: 65px;
}

.red_text{
  color: #D0021B;
}

.green_text{
  color: #2CC197;
}

.dashboard-section_content {
  padding: 50px 0 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

input{
  font-family: "Akrobat Regular", sans-serif;
  &::placeholder{
    font-family: "Akrobat Regular", sans-serif;
  }
}

.tab_custom{
  padding-bottom: 50px;
  button{
    background: transparent;
    color: #7F8FA4;
    font-family: "Akrobat Regular", sans-serif;
    font-size: 16px;
    margin-right: 30px;
    position: relative;
    transition: all 0.3s linear;
    cursor: pointer;
    &:hover{
      color: #334150;
    }
  }
  .active{
    color: #334150;
    position: relative;
    &:before{
      position: absolute;
      content: "";
      width: 100%;
      background: #0253B3;
      height: 2px;
      left: 0;
      bottom: -10px;
    }
  }

}

$i: 0;
@while $i <= 60 {
  $i: $i + 5;
  .gap-#{$i} {
    gap: #{$i}px;
  }
  .mt-#{$i} {
    margin-top: #{$i}px;
  }
  .mb-#{$i} {
    margin-bottom: #{$i}px;
  }
}