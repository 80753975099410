.price-add{
  margin-bottom: 30px;
  background: #fff;
  padding: 40px 50px;
}

.tab-button{
  margin-bottom: 40px;
  button{
    margin-right: 50px;
    font-size: 32px;
    line-height: 40px;
    color: #C2D1D9;
    cursor: default;
    background: transparent;
    font-family: "Akrobat Regular", sans-serif;
  }
  .active{
    color: #334150;
  }
}

//.btn-wrapper{
//  padding-top: 30px;
//  margin-top: 30px;
//  border-top: 1px solid #C2D1D9;
//  display: flex;
//  align-items: center;
//  width: 100%;
//  .default_button_wrapper {
//    margin-right: 14px;
//  }
//}

.btn_wrapper{
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  >div{
    display: flex;
  }
  .default_button_wrapper{
    margin-right: 15px;
  }
}
.info_summary{
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 20px 0 10px;
  div{
    padding-bottom: 10px;
    span{
      font-size: 16px;
    }
  }
}

.print_btn{
  border: 1px solid #0253B3;
  height: 41px;
  width: 147px;
  background: transparent;
  cursor: pointer;
  border-radius: 3px;
  color: #0253B3;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  &:before{
    position: absolute;
    left: 15px;
    top: calc(50% - 8px);
    content: "";
    background: url("../../assets/image/print.png") no-repeat;
    width: 18px;
    height: 16px;
  }
  &:hover{
    opacity:0.8 ;
  }
}