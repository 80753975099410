.refund_requests_table_wrapper{
  .transactions_table{
    .table_row{
      border-bottom: 2px solid rgba(0, 0, 0, 0.1);
      border-top: none;
    }
    .transactions_columns {
      width: 100%;
      .table_header {
        .row_item {
          display: flex;
          align-items: center;
        }
      }
      .table_body {
        .table_row {
          cursor: default;

          .row_item {
            display: flex;
            align-items: center;
            height: 100%;
            cursor: pointer;

            &_disabled {
              display: flex;
              align-items: center;
              height: 100%;
              cursor: not-allowed;
              pointer-events: inherit;
            }
          }
        }
      }
      .table_row {
        & > :first-child {
          width: 30%;
        }
        & > :nth-child(2) {
          width: 15%;
        }
        & > :nth-child(3) {
          width: 15%;
        }
        & > :nth-child(4) {
          width: 17%;
        }
        & > :nth-child(5) {
          width: 17%;
        }
        & > :nth-child(6) {
          width: 16%;
        }
        & > :nth-child(7) {
          width: 17%;
        }
        & > :nth-child(8) {
          width: 10%;
        }
        & > :nth-child(9) {
          width: 12%;
        }
        & > :last-child {
          min-width: 40px;
        }
      }
    }
  }
  .control_panel {
    display: flex;
    padding: 30px 0 0 0;
  }
}