.amount-info-wrapper {
  padding-top: 37px;
  display: flex;
  input {
    box-sizing: border-box;
    height: 41px;
    width: 301px;
    border: 1px solid #C2D1D9;
    border-radius: 3px;
    font-family: "Akrobat Regular", sans-serif;
    padding: 0 30px 0 12px;
    font-size: 14px;
    font-weight: 300;
    color: #334150;
    position: relative;
    &::placeholder {
      color: #7F8FA4;
    }

  }
  span {
    color: #7F8FA4;
    font-size: 14px;
    font-weight: 300;
    margin-left: -20px;
    z-index: 111;
    align-items: center;
    justify-content: center;
    display: flex;
  }
}

.transactions-dialog {
  z-index: 0!important;
}

.transactions_ul {
  width: 410px;
  .search_transactions_item {
    box-sizing: border-box;
    border-bottom: 1px solid #C2D1D9;
    padding: 15px 0px!important;
    background: #fff!important;
    cursor: pointer;
  }
  & > :last-child {
    border-bottom: none;
  }
  .search_transactions_no_items {
    box-sizing: border-box;
    padding: 15px 0px!important;
    background: #fff!important;
  }
}
