.snack_root {
  margin-bottom: 20px;
  & > div {
    padding: 5px 12px;
    border-radius: 5px;
    color: #334150;
    font-size: 16px;
    font-weight: 600;
    font-family: "Akrobat SemiBold", sans-serif;
    min-width: 400px;
    & > :first-child {
      padding: 10px 0 10px;
      span {
        img {
          margin-right: 8px;
        }
      }
    }
    button {
      padding: 5px;
    }
  }
  &.error_snack {
    div {
      background-color: #ff223b;
      font-family: "Inter", sans-serif;
      color: #ffffff;
      text-align: center;
      svg {
        fill: #ffffff;
      }
    }
  }
  &.success_snack {
    & > div {
      background-color: #ffffff;
      svg {
        fill: #C2D1D9;
      }
    }
  }
}