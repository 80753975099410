.checkbox-date {
    color: #7f8fa4;
    font-family: "Akrobat Light", sans-serif;
    font-size: 14px;
    margin-left: 4px;
    opacity: 0.7;
}

.checkbox-img {
    margin-left: 12px;
}

.main-container-block {
    margin-top: 50px;
    .dashboard-section_content {
        display: block;
        padding-top: 0;
        &:after {
            content: "";
            display: table;
            clear: both;
        }
        .price-item,
        .review-item {
            position: relative;
            float: left;
            margin-right: 2%;
            width: 23.5%;
            height: 265px;
            &:nth-child(4n) {
                margin-right: 0;
            }
            .block_value_extra {
                height: 40px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

.price_list_page {
    .markup_wrapper {
        display: flex;
    }
    .expansion-title {
        position: relative;
        .checkbox_custom {
            height: 40px;
            align-items: center;
            display: flex;
        }
        .markup_wrapper {
            position: absolute;
            left: 50%;
        }
    }
    .MuiTouchRipple-root-44 {
        display: none !important;
    }
}

.content-block {
    background: #fff;
    padding: 40px 50px;
    margin-bottom: 55px;
    h3 {
        padding-bottom: 14px;
    }
    .no-items {
        padding-top: 0;
    }
    &.content-block-reviews {
        margin-bottom: 30px;
    }
    .scroll_block {
        max-height: 400px;
        overflow: auto;
        &::-webkit-scrollbar {
            width: 4px;
        }
        &::-webkit-scrollbar-track {
            -webkit-border-radius: 2px;
            border-radius: 2px;
            margin-right: 5px;
            background: #edf2f8;
        }
        &::-webkit-scrollbar-thumb {
            -webkit-border-radius: 10px;
            border-radius: 10px;
            background: #01397c;
        }
        &::-webkit-scrollbar-thumb:window-inactive {
            background: #01397c;
        }
    }
}

.info-time {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    img {
        margin-right: 5px;
    }
    span {
        color: #7f8fa4;
        font-size: 12px;
        font-weight: 300;
        line-height: 15px;
    }
}

.reviews-wrapper {
    .name {
        color: #01397c;
        font-size: 23px;
        font-weight: 600;
        line-height: 29px;
    }
}

.rating {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    span {
        color: #7f8fa4;
        font-size: 14px;
        padding-left: 5px;
    }
}
.reviews:last-of-type {
    border: none;
    padding: 30px 0 40px;
}
.reviews {
    padding: 30px 0 30px;
    border-bottom: 1px solid #c2d1d9;
    .rating_wrapper {
        margin-bottom: 10px;
    }
    .descriptions {
        color: #334150;
        font-size: 14px;
        line-height: 17px;
        max-width: 650px;
        padding: 0 0 15px;
        &:last-of-type {
            padding-bottom: 0;
            word-break: break-all;
        }
        span {
            font-family: "Akrobat SemiBold", sans-serif;
        }
    }
    textarea {
        font-family: "Akrobat Regular", sans-serif;
        resize: none;
        box-sizing: border-box;
        height: 60px;
        font-size: 14px;
        max-width: 650px;
        width: 100%;
        border: 1px solid #c2d1d9;
        border-radius: 3px;
        padding: 12px 11px;
        margin-bottom: 10px;
        &::placeholder {
            font-family: "Akrobat Regular", sans-serif;
            color: #7f8fa4;
            font-size: 14px;
            font-weight: 300;
        }
    }
    .default_button_wrapper .default_button.default_button_contained {
        background-color: #0253b3;
        color: #ffffff;
        border: none;
    }
}

.markup_wrapper {
    position: relative;
    height: 40px;
    display: none;
    align-items: center;
    input {
        border: 1px solid #c2d1d9;
        box-sizing: border-box;
        height: 31px;
        width: 56px;
        border-radius: 3px;
        color: #334150;
        padding: 0 22px 0 12px;
        font-size: 14px;
        font-family: "Akrobat Regular", sans-serif;
        &::placeholder {
            color: #7f8fa4;
        }
    }
    > span {
        color: #7f8fa4;
        font-family: "Akrobat Light", sans-serif;
        font-size: 12px;
        font-weight: 300;
        line-height: 18px;
        padding-right: 16px;
    }
    .box_icon {
        color: #7f8fa4;
        position: absolute;
        right: 12px;
        font-size: 14px;
        font-family: "Akrobat Light", sans-serif;
        font-weight: 300;
    }
    p {
        position: absolute;
        top: calc(50% - 10px);
        left: 130px;
        color: red;
        width: 250px;
    }
}

.check_field_wrapper {
    color: transparent !important;
    display: flex !important;
    label {
        margin: 0 !important;
        & > :first-child > :last-child > :first-child {
            width: 17px !important;
            height: 17px !important;
            top: 1px !important;
            left: 1px !important;
        }
        & > :last-child {
            color: #334150 !important;
            font-size: 14px !important;
            margin-left: 9px !important;
            font-weight: 400 !important;
            font-family: "Akrobat Regular", sans-serif !important;
        }
    }
    .custom_check {
        padding: 0 !important;
    }
}

.expansion-title {
    color: transparent;
    display: flex;
    label {
        margin: 0;
        & > :first-child > :last-child > :first-child {
            width: 17px !important;
            height: 17px !important;
            top: 1px !important;
            left: 1px !important;
        }
        & > :last-child {
            color: #334150;
            font-size: 14px;
            margin-left: 5px;
            font-weight: 400;
            font-family: "Akrobat Regular", sans-serif;
        }
    }
    .custom_check {
        padding: 0;
    }
    .approve {
        .custom_check {
            display: none;
        }
    }
}

.expansion-block {
    display: flex !important;
    flex-direction: column;
    button {
        padding: 5px;
        svg {
            font-size: 18px;
        }
    }
}
.schedule_map_wrapper {
    display: flex;
    flex-direction: column;
    > div {
        height: 27px;
        display: flex;
        align-items: center;
    }
    & > label {
        margin: 5px 0 !important;
    }
}
.expansion-body {
    padding-left: 27px;
}
