.default_button_wrapper {
  .default_button {
    text-align: center;
    display: inline-flex;
    font-family: "Akrobat SemiBold", sans-serif;
    font-size: 14px;
    font-weight: 600;
    height: 40px;
    min-width: 147px;
    padding: 0 10px;
    border-radius: 3px;
    box-shadow: none;
    white-space: nowrap;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    &:disabled {
      opacity: 0.7;
    }
    &.default_button_contained {
      background-color: #0253B3;
      color: #ffffff;
      border: 1px solid #0253B3;
    }
    &.default_button_contained_green {
      background-color: #16D761;
      color: #ffffff;
      border: 1px solid #16D761;
    }
    &.default_button_outlined {
      color: #ffffff;
      border: 1px solid #ffffff;
      &.cancel_btn {
        border: 1px solid #7F8FA4;
        color: #7F8FA4;
      }
      &.delete_btn {
        border: 1px solid #D0021B;
        color: #D0021B;
      }
      &.waiting_for_documents {
        border: 1px solid #0253B3;
        color: #0253B3;
      }
      &.waiting_for_details {
        border: 1px solid #249AF3;
        color: #249AF3;
      }
    }
    &.default_button_reject {
      border: 1px solid #D0021B;
      background-color: #D0021B;
      &:hover {
        background-color: #bf061b;
      }
    }
    div {
      color: #ffffff;
    }
  }
  .full_btn_width {
    width: 100%;
  }
  &--last_btn {
    margin-left: auto;
    margin-right: 0;
  }
}

