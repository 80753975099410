.info-success-buy{
  display: flex;
  min-width: 420px;
  justify-content: space-between;
  height: 50px;
  align-items: center;
  padding: 20px;
  position: fixed;
  left: 50%;
  bottom: 10%;
  transform: translate(-50%);
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  background: #fff;
  z-index: 9999999;
  border-radius: 5px;
  & > div{
    display: flex;
    flex-flow: row;
    align-items: center;
    & > p{
      color: #334150;
      font-family: Akrobat;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      margin-bottom: 0;
    }
    & > span{
      margin-right: 5px;
      & > img{
        margin-top: -2.5px;
      }
    }
  }
  span.close{
    cursor: pointer;
  }
}
