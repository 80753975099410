.popper_wrapper {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  color: #334150;
  font-family: "Akrobat Regular", sans-serif;
  font-size: 14px;
  padding: 5px 20px;
  width: max-content;
  position: relative;
  margin-top: 5px;
  max-height: 300px !important;
  overflow: auto;
  &.products_popper {
    padding: 5px 0;
  }
}