.comment-component {
    .comment-component__header {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
    }
    .comment-component__body {
        margin-top: 10px;
    }
    .comment-component-header__title{
        color: var(--color-constants-blue-dark);
    }
}