//.pagination_wrapper {
//  display: flex;
//  align-items: center;
//  justify-content: space-between;
//  padding: 30px 0 0 0;
//  background-color: transparent;
//}
//
//.pagination {
//  display: flex;
//  align-items: center;
//  & > :first-child {
//    margin-left: 0;
//  }
//  & > :last-child {
//    margin-right: 0;
//  }
//}
//
//.pagin_item {
//  display: flex;
//  align-items: center;
//  justify-content: center;
//  width: 28px;
//  height: 28px;
//  margin: 0 2px;
//  &.pagin_item_active {
//    a {
//      background-color: #0253B3;
//      border-radius: 2px;
//      color: #ffffff;
//      opacity: 1;
//      box-shadow: 0 2px 42px 0 rgba(0,0,0,0.08);
//      &:hover {
//        color: #ffffff;
//      }
//    }
//  }
//  a {
//    display: block;
//    text-align: center;
//    width: 100%;
//    height: 100%;
//    border-radius: 2px;
//    color: #0253B3;
//    font-family: "Akrobat Regular", sans-serif;
//    font-size: 14px;
//    line-height: 28px;
//    text-decoration: none;
//    -webkit-transition: all 0.3s ease-out;
//    -moz-transition: all 0.3s ease-out;
//    -ms-transition: all 0.3s ease-out;
//    -o-transition: all 0.3s ease-out;
//    transition: all 0.3s ease-out;
//    &:hover {
//      text-decoration: none;
//      text-shadow: 0 0 15px #000000;
//    }
//  }
//}
//
//.pagin_prev, .pagin_next {
//  margin: 0;
//  &.disabled {
//    a {
//      opacity: 0.5;
//      cursor: not-allowed;
//    }
//  }
//  a {
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    svg {
//      font-size: 20px;
//      fill: #0253B3;
//    }
//  }
//}

.pagination-container{
  display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0 0 0;
    background-color: transparent;
}

.pagination-list {
  display: flex;
  .disabled{
    a{
      //cursor: auto;
    }
  }
  li {
    border-radius: 2px;
    width: 28px;
    height: 28px;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 3px;
    -webkit-transition: all ease .3s;
    -moz-transition: all ease .3s;
    -ms-transition: all ease .3s;
    -o-transition: all ease .3s;
    transition: all ease .3s;
    &:hover{
      border: 1px solid #0253B3;
    }
    a{
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    img {
      margin-top: -3px;
      opacity: 0.54;
    }
  }
}


.pagination-link {
  cursor: pointer;
  background: transparent;
  color: #0253B3;
  text-decoration: none;
  text-align: center;
  line-height: 28px;
  height: 28px;
  width: 28px;
  font-size: 14px;
  min-width: 28px;
  &.pagination-link-active {
    border-radius: 2px;
    background-color: #0253B3;
    box-shadow: 0 2px 42px 0 rgba(0,0,0,0.08);
    color: #ffffff;
    &:hover{
      opacity: 0.7;
    }
  }
}

.pagination-ellipsis {
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  a {
    cursor: pointer;
    background: transparent;
    height: 28px;
    width: 28px;
    color: #0253B3;
    text-decoration: none;
    text-align: center;
    line-height: 28px;
  }
}