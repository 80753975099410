.custom_input_wrapper {
  width: 100%;
  max-width: 300px;
  .custom_input_label {
    font-family: "Akrobat Regular", sans-serif;
    color: #C2D1D9;
    font-size: 14px;
    transform: translate(11px, 13px) scale(1);
    font-weight: 400;
    &.custom_input_label_focused {
      color: rgba(255,255,255,0.72);
    }
    &.custom_input_label_active {
      color: #01397C;
      transform: translate(13px, -6px) scale(0.75);
    }
    &.custom_input_label_error {
      color: #ff0000;
    }
  }
  .custom_input {
    font-family: "Akrobat Regular", sans-serif;
    background-color: #FFFFFF;
    color: #01397C;
    font-size: 14px;
    border-radius: 4px;
    max-height: 40px;
    &:hover {
      .custom_input_outline {
        border-color: #C2D1D9!important;
      }
    }
    &:focus {
      background-color: #FFFFFF;
    }
    input {
      padding: 12px 14px;
    }
    .custom_input_outline {
      border-color: #C2D1D9;
      legend {
      }
    }
    &.custom_input_focused {
      background-color: #FFFFFF;
      .custom_input_outline {
        border: 1px solid #01397C;
      }
    }
    &.custom_input_error, &.custom_input_error:hover {
      .custom_input_outline {
        border-color: #ff0000!important;
      }
    }
    &.custom_input_disabled {
      color: #334150;
    }
  }
  .custom_input_adorned_end {
    padding-right: 5px;
    & > div:last-of-type {
      margin-right: 8px;
      span {
        color: #C2D1D9;
        right: 12px;
        position: absolute;
        &.error_empty {
          right: 0;
          width: 100%;
          height: 15px;
          top: -10px;
        }
      }
      svg {
        fill: #ff0000;
        cursor: pointer;
      }
      .password_type_btn {
        padding: 0;
        width: 25px;
        height: 25px;
        &:hover {
          background-color: transparent;
        }
        svg {
          fill: #C2D1D9;
        }
      }
    }
  }
  .custom_input_adorned_start {
    & > div:first-of-type {
      margin-right: 0;
      span {
        color: #C2D1D9;
      }
    }
  }
}