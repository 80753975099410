.disposal_balances_table{
  .transactions_columns {
    width: 100%;
    
    .table_row {
      border-bottom: 2px solid rgba(0, 0, 0, 0.1);
      border-top: none;
      display: flex;
      align-items: center;
      padding: 8px 0;
      min-height: 48px;
    }

    .table_header {
      .row_item {
        display: flex;
        align-items: center;
        font-weight: 500;
      }
    }

    .table_row {
      & > :first-child {
        width: 15%;
        padding-right: 24px;
      }
      
      & > :nth-child(2) {
        width: 25%;
        padding-right: 24px;
      }
      
      & > :nth-child(3) {
        width: 20%;
        padding-right: 24px;
      }
      
      & > :nth-child(4) {
        width: 25%;
        padding-right: 24px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      
      & > :last-child {
        width: 15%;
        padding-right: 24px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

}