.orders-main-block {
  & > .title-block {
    h1 {
      margin-top: 50px;
      margin-bottom: 30px;
    }
  }

  & > .dashboard-section_content {

    .wrapper-upload {
      p {
        width: 213px;
        color: #334150;
        font-size: 32px;
      }

      .form-control {
        margin: 20px 0;
        .form-input--file {
          position: relative;
          overflow: hidden;
          padding-right: 120px;
          text-overflow: ellipsis;
          white-space: nowrap;
          //-----
          display: flex;
          width: 301px;
          height: 41px;
          line-height: 41px;
          padding-left: 12px;
          border: 1px solid #C2D1D9;
          border-radius: 3px;

          .form-input-file {
            opacity: 0;
            visibility: hidden;
            position: absolute;
            top: 0;
            left: 0;
          }

          .form-input--file-button {
            cursor: pointer;
            display: inline-block;
            position: absolute;
            right: -1px;
            background-color: #0253B3;
            color: #fff;
            height: 100%;
            line-height: 41px;
            width: 98px;
            text-align: center;
            border-radius: 0 3px 3px 0;
          }
        }

      }
    }
    & > .content-block{
      padding: 0;
    }
  }
  & > hr{
    background: red !important;
  }
  & > .dashboard-section_content.dashboard-import-orders{
    flex-direction: column;
    margin: 0;
  }
}

.import_order_page{
  .drop_down_block{
    display: flex;
    flex-flow: column;
    >span{
      color: #7F8FA4;
      font-family: "Akrobat", sans-serif;
      font-size: 12px;
      font-weight: 300;
      line-height: 18px;
      padding-bottom: 4px;
    }
    .select_wrapper{
      width: 301px;
      & > .select_component{
        .select__menu{
          .select__menu-list{
            max-height: 300px;
            overflow: auto;
            &::-webkit-scrollbar {
              width: 4px;
            }
            &::-webkit-scrollbar-track {
              -webkit-border-radius: 2px;
              border-radius: 2px;
              margin-right: 5px;
              background: #EDF2F8;
            }
            &::-webkit-scrollbar-thumb {
              -webkit-border-radius: 10px;
              border-radius: 10px;
              background: #01397C;
            }
            &::-webkit-scrollbar-thumb:window-inactive {
              background: #01397C;
            }
          }

        }
      }
    }
  }
  .form-input--file-text{
    color: #7F8FA4;
    font-weight: 300;
  }
  h3{
    padding-bottom: 10px;
  }
  .form-input--file-button{
    text-transform: uppercase;
  }
  .expansion-title{
    position: relative;
    .checkbox_custom{
      height: 30px;
      align-items: center;
      display: flex;
    }

  }
  .MuiTouchRipple-root-44{
    display: none!important;
  }
}


.edit_btn_wrapper{
  display: flex;
  .error_after_button{
    padding: 0 10px;
    line-height: 40px;
    color: red;
    font-family: "Akrobat", sans-serif;
  }
  .success_after_button{
    padding: 0 10px;
    line-height: 40px;
    color: green;
    font-family: "Akrobat", sans-serif;
  }
.loader_wrapper{
  & > div{
    width: 30px !important;
    height: 30px !important;
  }
}
}


.import-orders-dialog{
  & > .title{
    height: 40px;
    width: 731px;
    color: #334150;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 18px;
  }
}

.test > label > span{
  color: blue !important;
}

.waiting-company {
  display: flex ;
  flex-direction: column;
  padding: 5px 0;

  .item-content {
    height: 30px;
    display: flex;
    flex-flow: row wrap;
    max-width: 60%;
  }

  button {
    padding: 5px;

    svg {
      font-size: 18px;
    }
  }

  .name-company {
    color: #334150;
    font-size: 14px;
    font-weight: 400;
    font-family: "Akrobat Regular", sans-serif;
    cursor: pointer;
  }

  &__btn {
    order: 999;
    margin-left: auto;
    background-color: #0253B3;
    color: #fff;
    border-radius: 3px;
    height: 30px;
    padding: 8px 10px !important;
    cursor: pointer;
    transition: all 0.3s linear;
    &:hover {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }
  }
}

