.submit_order_error {
    cursor: pointer;
    svg {
        fill: #ff0000;
    }
}

.summary_table {
    .title_table {
        color: #334150;
        font-size: 23px;
        line-height: 29px;
        padding-bottom: 10px;
    }
    .transactions_columns {
        width: 100%;
        .table_header {
            .row_item {
                display: flex;
                align-items: center;
            }
        }
        .table_row {
            & > :first-child {
                width: 21%;
            }
            & > :nth-child(2) {
                width: 21%;
            }
            & > :nth-child(3) {
                width: 21%;
            }
            & > :nth-child(4) {
                width: 21%;
            }
            & > :last-child {
                width: 16%;
            }
        }
    }
}
.summary_auto {
    .transactions_columns {
        width: 100%;
        .table_header {
            .row_item {
                display: flex;
                align-items: center;
            }
        }
        .table_row {
            & > :first-child {
                width: 16%;
            }
            & > :nth-child(2) {
                width: 16%;
            }
            & > :nth-child(3) {
                width: 16%;
            }
            & > :nth-child(4) {
                width: 16%;
            }
            & > :nth-child(5) {
                width: 16%;
            }
            & > :last-child {
                width: 16%;
            }
        }
    }
}
.summary_page {
    .btn_wrapper {
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        padding-top: 30px;
        display: flex;
        justify-content: space-between;
        > div {
            display: flex;
        }
        .default_button_wrapper {
            margin-right: 15px;
        }
    }
    .info_summary {
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        padding: 20px 0 10px;
        div {
            font-weight: 600;
            padding-bottom: 10px;
            span {
                font-size: 16px;
            }
        }
    }
}

.print_btn {
    border: 1px solid #0253b3;
    height: 41px;
    width: 147px;
    background: transparent;
    cursor: pointer;
    border-radius: 3px;
    color: #0253b3;
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    &:before {
        position: absolute;
        left: 15px;
        top: calc(50% - 8px);
        content: "";
        background: url("../../../assets/image/print.png") no-repeat;
        width: 18px;
        height: 16px;
    }
    &:hover {
        opacity: 0.8;
    }
}

.print_goods {
    .transactions_columns {
        width: 100%;
        .table_header {
            .row_item {
                display: flex;
                align-items: center;
            }
        }
        .table_row {
            & > :first-child {
                width: 21%;
            }
            & > :nth-child(2) {
                width: 21%;
            }
            & > :nth-child(3) {
                width: 21%;
            }
            & > :nth-child(4) {
                width: 21%;
            }
            & > :last-child {
                width: 16%;
            }
        }
    }
}

.print_goods_auto {
    .transactions_columns {
        width: 100%;
        .table_header {
            .row_item {
                display: flex;
                align-items: center;
            }
        }
        .table_row {
            & > :first-child {
                width: 16%;
            }
            & > :nth-child(2) {
                width: 16%;
            }
            & > :nth-child(3) {
                width: 16%;
            }
            & > :nth-child(4) {
                width: 16%;
            }
            & > :nth-child(5) {
                width: 16%;
            }
            & > :last-child {
                width: 16%;
            }
        }
    }
}

.no_margin {
    margin-top: 0px !important;
}
