.sidebar_wrapper {
  .sidebar_paper {
    background-color: #01397C;
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    p {
      width: 250px;
      color: #ffffff;
      font-size: 25px;
      margin-bottom: 40px;
      position: relative;
      cursor: pointer;

      .active{
        opacity: 1;
      }

    }

  }
}



.line_side_bar{
  margin-bottom: 45px;
  background: #FFFFFF;
  height: 2px;
  opacity: 0.5;
}
.linkClass {
  opacity: 0.5;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  text-decoration: none;
  color: #FFFFFF;
  &:hover{
    opacity: 1;
  }
}